import { useState, useRef } from 'react';

import Button from '@library/Button/Button';
import Toggle from '@library/Toggle/Toggle';
import cookieCategories from '../settings/categories.config';
import { prepCategories } from './cookie-form.utils';

import './cookie-form.styl';

function CookieForm(props) {
  const defaultCategorySelectionRef = useRef(prepCategories(cookieCategories));
  const [selectedCategories, setSelectedCategories] = useState(defaultCategorySelectionRef.current);
  
  const optionalCategories = selectedCategories.filter(categorie => !categorie.required);
  const isUntouched = (defaultCategorySelectionRef.current === selectedCategories);

  function updateCategorySelection(e) {
    const updatedFormState = selectedCategories.map((curr) => {
      return (curr.id === e.target.name) ? { ...curr, accepted: e.target.checked } : { ...curr };
    })
    setSelectedCategories(updatedFormState);
  }

  function onSubmit(essentialOnly) {
    if (essentialOnly) {
      props.onSubmit([...defaultCategorySelectionRef.current]);
    } else if (isUntouched) {
      props.onSubmit(selectedCategories.map(curr => ({ ...curr, accepted: true })));
    } else {
      props.onSubmit([...selectedCategories]);
    }
  }

  return (
    <form className="cookie-form">
      {!!optionalCategories.length && (
        <div className="cookie-form__input-group">
          <h2 className="cookie-form__input-group__title">
            Additional cookies:
          </h2>
          {optionalCategories.map(cookieCategorie => (
            <Toggle
              key={cookieCategorie.id}
              name={cookieCategorie.id}
              className="cookie-form__input-group__cookie-toggle"
              checked={cookieCategorie.accepted}
              onChange={e => updateCategorySelection(e)}
            >
              <span className="cookie-form__input-group__cookie-toggle__label-title">
                {cookieCategorie.label}
              </span>
              <span>
                {cookieCategorie.description}
              </span>
            </Toggle>
          ))}
        </div>
      )}
      <div className="cookie-form__buttons">
        <Button 
          classType="flat" 
          className="cookie-form__buttons__button"
          onClick={() => onSubmit(true)}
        >
          Allow essential cookies only
        </Button>
        <Button 
          classType="secondary" 
          className="cookie-form__buttons__button"
          onClick={() => onSubmit()}
        >
          {isUntouched ? 'Accept all cookies' : 'Save your choice'}
        </Button>
      </div>
    </form>
  )
}

export default CookieForm;