import { InlineWidget } from 'react-calendly';

import './calendar.styl';

function Calendar() {
  return (
    <div className="calendar">
      <InlineWidget url="https://calendly.com/ides-kl8/30min" />
    </div>
  )
}

export default Calendar;