/* eslint-disable react/no-unknown-property */
import './satellite.styl';

function Satellite(props) {
  const { animateActive = true, ...otherProps } = props;

  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="visual__satellite"
      viewBox="0 0 600 300"
      {...otherProps}
    >
      <g>
        {animateActive && (
          <animateMotion
            dur="50s"
            repeatCount="indefinite"
            path="M20,50 C20,-50 180,150 180,50 C180-50 20,150 20,50 z"
          />
        )}
        {props.children}
        <g transform="translate(0 1.083)">
          <rect x="21.3" y="65.5" className="st0" width="129.5" height="48.5" />
          <line className="st1" x1="162" y1="90.6" x2="22.3" y2="89.4" />
          <line className="st1" x1="53" y1="113.3" x2="52.6" y2="66.4" />
          <line className="st1" x1="86.4" y1="112.6" x2="86.1" y2="65.7" />
          <line className="st1" x1="119" y1="112.5" x2="119.4" y2="66.4" />
        </g>
        <g transform="translate(1621.966 292.855) rotate(180)">
          <rect x="1244.1" y="178.5" className="st0" width="129.5" height="48.5" />
          <line className="st1" x1="1384.7" y1="203.5" x2="1245" y2="202.4" />
          <line className="st1" x1="1275.6" y1="226.3" x2="1275.3" y2="179.4" />
          <line className="st1" x1="1309.1" y1="225.6" x2="1308.7" y2="178.7" />
          <line className="st1" x1="1341.7" y1="225.5" x2="1342" y2="179.4" />
        </g>
        <g className="visual__satellite__wave">
          <path 
            className="st2" 
            d="M256.7,249.6c-31,31.3-81.6,31.7-113,0.6"
          />
          <path 
            className="st2" 
            d="M256.7,249.6c-31,31.3-81.6,31.7-113,0.6"
          />
        </g>
        <g className="visual__satellite__wave visual__satellite__wave2">
          <path 
            className="st2" 
            d="M256.7,249.6c-31,31.3-81.6,31.7-113,0.6"
          />
          <path 
            className="st2" 
            d="M256.7,249.6c-31,31.3-81.6,31.7-113,0.6"
          />
        </g>
        <g className="visual__satellite__wave visual__satellite__wave3">
          <path 
            className="st2" 
            d="M256.7,249.6c-31,31.3-81.6,31.7-113,0.6"
          />
          <path 
            className="st2" 
            d="M256.7,249.6c-31,31.3-81.6,31.7-113,0.6"
          />
        </g>
        <g>
          <path
            className="st2"
            d="M175.1,144.3c0,0-1.4-101-0.2-110.2s11.4-19.6,23.5-19.8c12.1-0.3,23.8,10.1,24.8,18.8c1,8.7,1,111.5,1,111.5L175.1,144.3z"
          />
          <path className="st2" d="M187.5,182.6l-26.2-0.9c0,0,9-32.2,39.3-30.7s38,32.1,38,32.1l-25.8,0.2" />
          <line className="st1" x1="199.6" y1="199.9" x2="199.8" y2="172.2" />
          <circle className="st3" cx="200" cy="201.9" r="6.5" />
        </g>
      </g>
    </svg>
  )
}

export default Satellite;