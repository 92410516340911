import Flickity from 'react-flickity-component';

import 'flickity/css/flickity.css';
import './media.styl';

function Media() {
  return (
    <div className="media" id="media">
      <Flickity options={{ freeScroll: true, wrapAround: true, groupCells: true }}>
        <div className="media__item" style={{ backgroundImage: 'url(/img/slider/explore-detail.png)' }}>
          <p className="media__item__text">
            Compare images of different days and with different indicators.
          </p>
        </div>
        <div className="media__item" style={{ backgroundImage: 'url(/img/slider/explore-vn-landing.png)' }}>
          <p className="media__item__text">
            The application comes with user authentication.
          </p>
        </div>
        <div className="media__item" style={{ backgroundImage: 'url(/img/slider/explore.png)' }}>
          <p className="media__item__text">
            The Explore-VN interface.
          </p>
        </div>
        <div className="media__item" style={{ backgroundImage: 'url(/img/slider/time-series.png)' }}>
          <p className="media__item__text">
            Analyse the state of a waterbody with time series.
          </p>
        </div>
        <div className="media__item" style={{ backgroundImage: 'url(/img/slider/download.png)' }}>
          <p className="media__item__text">
            Images can be downloaded for offline processing.
          </p>
        </div>
      </Flickity>
    </div>
  )
}

export default Media;