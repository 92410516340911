import theme from '@css/theme.style.json';

import Satellite from './Satellite/Satellite';
import Grid from './Grid/Grid';
import Waves from './Waves/Waves';
import Meteors from './Meteors/Meteors';
import Bubbles from './Bubbles/Bubbles';

import './visual.styl';

function Visual() {
  return (
    <section className="visual">
      <div className="visual__intro">
        <h1 className="visual__intro__title">EXPLORE</h1>
        <p className="visual__intro__text">
          {'EXPLORE is a cloud-based solution that uses remote sensing for the efficient '}
          {'monitoring of, for example, water quality of inland waters and the coastal '}
          {'area over time and in near-real time. Learn more by contacting '}
          <a 
            className="visual__intro__text__url" 
            href="https://www.nazka.be/en/contact" 
            target="_blank" 
            rel="noreferrer"
          >
            Nazka Mapps
          </a>
          .
        </p>
      </div>
      <svg 
        className="visual__svg" 
        xmlns="http://www.w3.org/2000/svg" 
        version="1.1" 
        viewBox="0 0 1920 1080" 
        fill={theme.color.primary.main}
      >
        <rect width="1920" height="1080" fill={theme.color.primary.main} />
        <Meteors />
        <Waves />
        <Grid />
        <Bubbles />
      </svg>
      <Satellite />
      <button 
        type="button" 
        className="visual__scroll-down"
        onClick={() => {
          const scrollTarget = document.querySelector('#about');
          scrollTarget.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <span>DEEP DIVE</span>
      </button>
    </section>
  )
}

export default Visual;