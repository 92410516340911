import axios from 'axios';

async function setSignedCookies(cookies, acceptedCookiesArray) {
  if (import.meta.env.VITE_NAZKA_ENV === 'production') {
    await axios.post(
      'https://cookieservice.prod.lambda.nazkamapps.com', 
      `{"website":"${window.location.hostname}", "acceptedCookies":${JSON.stringify(acceptedCookiesArray)}}`,  
      { headers: { 'Content-Type': 'application/json;charset=UTF-8', Accept: 'application/json, text/plain, */*' } },
    ).catch((e) => {
      console.log(e);
    });
  }

  return axios.get('/set-signed-cookies', { params: cookies });
}

export {
  setSignedCookies,
}