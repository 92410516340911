/* eslint-disable max-lines */
/* eslint-disable no-useless-escape */
import Button from '@library/Button/Button';

import './footer.styl';

function Footer() {
  return (
    <section className="footer" id="footer">
      {/* footer imported from nazka.be */}
      <div className="nazka-page-footer">
        <div className="container">
          <div className="nazka-page-footer__content">
            <div className="nazka-page-footer__content__contact">
              <div className="nazka-page-footer__content__contact__logo">
                <img src="/img/logo/logo-nazka-icon-white.svg" alt="logo Nazka Mapps" />
              </div>
              <div className="nazka-page-footer__content__contact__cols">
                <div className="nazka-page-footer__content__contact__cols__col">
                  <a href="mailto:info@nazka.be" target="_top">info@nazka.be</a>
                </div>
                <div className="nazka-page-footer__content__contact__cols__col">
                  <span className="nazka-page-footer__content__contact__cols__col__span">
                    <a href="tel:+32485383213">+32 (0)485 38 32 13</a>
                    {' (Han)'}
                  </span>
                  <span className="nazka-page-footer__content__contact__cols__col__span">
                    <a href="tel:+32498844167">+32 (0)498 84 41 67</a>
                    {' (Ides)'}
                  </span>
                </div>
                <div className="nazka-page-footer__content__contact__cols__col nazka-page-footer__content__contact__cols__col--social">
                  <a href="https://www.facebook.com/nazka.be/?ref=br_rs" target="_blank" rel="noreferrer">
                    <img src="/img/social/facebook-f-blue.svg" alt="facebook" />
                  </a>
                  <a href="https://twitter.com/nazka_mapps/" target="_blank" rel="noreferrer">
                    <img src="/img/social/twitter-blue.svg" alt="X social media platform" />
                  </a>
                  <a href="https://www.linkedin.com/company/nazka-mapps-bvba/" target="_blank" rel="noreferrer">
                    <img src="/img/social/linkedin-in-blue.svg" alt="linkedin" />
                  </a>
                </div>
                <a target="_blank" href="https://nazka.be/en/contact" rel="noreferrer">Contact us</a>
              </div>
            </div>
            <div className="nazka-page-footer__content__newsletter">
              <div className="nazka-page-footer__content__newsletter__title">
                Do you want to stay up-to-date?
              </div>
              <div className="nazka-page-footer__content__newsletter__content">
                <div className="nazka-page-footer__content__newsletter__content__intro">
                  Subscribe to the Nazka Mapps newsletter
                </div>
                <div id="newsletter-error" className="nazka-page-footer__content__newsletter__content__error" />
                <div className="nazka-page-footer__content__newsletter__content__form-wrapper">
                  <form className="nazka-page-footer__content__newsletter__content__form-wrapper__form js-newsletter-form">
                    <input id="newsletter-input" type="email" placeholder="your email address" />
                    <input id="newsletter-language" type="hidden" value="en" />
                    <div className="nazka-page-footer__content__newsletter__content__form__wrap">
                      <div className="nazka-page-footer__content__newsletter__content__form__wrap-wrapper__checkbox">
                        <input id="newsletter-checkbox" type="checkbox" />
                        <label htmlFor="newsletter-checkbox">
                          {'I confirm that I have read and approved the '}
                          <a target="_blank" href="https://legal.nazka.be/docs/en/privacy-policy/" rel="noreferrer">privacy policy</a>
                          .
                        </label>
                      </div>
                      <Button 
                        type="submit"
                        disabled
                      >
                        <span className="nazka-spinner nazka-spinner">
                          <span className="nazka-spinner__circle" />
                        </span>
                        Sign Up
                      </Button>
                    </div>
                  </form>
                  <div id="newsletter-success" className="nazka-page-footer__content__newsletter__content__form-wrapper__feedback">You have been added to the Nazka newsletter.</div>
                </div>
              </div>
            </div>
            <div className="nazka-page-footer__content__generic">
              <div className="nazka-page-footer__content__generic__title">
                OFFICE
              </div>
              <div className="nazka-page-footer__content__generic__items nazka-page-footer__content__generic__items--address">
                <span>Tiensevest 100</span>
                <span>3000 Leuven</span>
                <span>Belgium</span>
                <a href="https://www.google.com/maps/dir//Tiensevest+100,+3000+Leuven/@50.8774559,4.7111106,16z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x47c1672ace3cfb21:0x6c80657795e0c944!2m2!1d4.715488!2d50.877456!3e0" target="_blank" rel="noreferrer">Show route</a>
              </div>
            </div>
            <div className="nazka-page-footer__content__generic">
              <div className="nazka-page-footer__content__generic__title">
                SITEMAP
              </div>
              <div className="nazka-page-footer__content__generic__items nazka-page-footer__content__generic__items">
                <a
                  onClick={() => {
                    const element = document.querySelector('#about');
                    element.scrollIntoView({ behavior: 'smooth' });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  About
                </a>
                <a
                  onClick={() => {
                    const element = document.querySelector('#media');
                    element.scrollIntoView({ behavior: 'smooth' });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Media
                </a>
                <a
                  onClick={() => {
                    const element = document.querySelector('#features');
                    element.scrollIntoView({ behavior: 'smooth' });
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  Features
                </a>
              </div>
            </div>
            <div className="nazka-page-footer__content__generic">
              <div className="nazka-page-footer__content__generic__title">
                EXTRA
              </div>
              <div className="nazka-page-footer__content__generic__items nazka-page-footer__content__generic__items">
                <a target="_blank" href="https://nazka.be/en/" rel="noreferrer">Nazka Mapps</a>
              </div>
              <div className="nazka-page-footer__content__generic__items nazka-page-footer__content__generic__items">
                <a target="_blank" href="https://nazka.be/en/realisations#js-realisations-products" rel="noreferrer">Other products</a>
              </div>
              <div className="nazka-page-footer__content__generic__items nazka-page-footer__content__generic__items">
                <a target="_blank" href="https://mapframe.be" rel="noreferrer">Mapframe</a>
              </div>
              <div className="nazka-page-footer__content__generic__items nazka-page-footer__content__generic__items">
                <a target="_blank" href="https://nazka.be/en/press" rel="noreferrer">Press</a>
              </div>
            </div>
          </div>
        </div>
        <div className="nazka-page-footer__content__newsletter__legal">
          <span>
            &copy;
            {`${new Date().getFullYear()} Nazka Mapps` }
          </span>
          <div className="nazka-page-footer__content__newsletter__legal__links">
            <a href="https://legal.nazka.be/docs/en/terms-of-use/" target="_blank" rel="noreferrer">terms of use</a>
            <a href="https://legal.nazka.be/docs/en/privacy-policy/" target="_blank" rel="noreferrer">privacy</a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;