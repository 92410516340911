import Icon from '@library/Icon/Icon';
import {
  faCalendarWeek, faChartLineUpDown, faCloudArrowDown, faCodeFork, faRankingStar, 
} from '@fortawesome/pro-thin-svg-icons';

import './features.styl';

function Features() {
  return (
    <div className="features" id="features">
      {/* <h2 className="features__title">Features</h2> */}
      <div className="features__list">
        <div className="features__list__card">
          <Icon icon={faCalendarWeek} />
          <span>updates every 5 days</span>
        </div>
        <div className="features__list__card">
          <Icon icon={faRankingStar} />
          <span>state-of-the-art algorithms</span>
        </div>
        <div className="features__list__card">
          <Icon icon={faChartLineUpDown} />
          <span>statistics</span>
        </div>
        <div className="features__list__card">
          <Icon icon={faCodeFork} />
          <span>adaptable</span>
        </div>
        <div className="features__list__card">
          <Icon icon={faCloudArrowDown} />
          <span>data download</span>
        </div>
      </div>
    </div>
  )
}

export default Features;