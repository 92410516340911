import theme from '@css/theme.style.json';

import './grid.styl';

function Grid() {
  return (
    <svg width="100%" height="100%" className="visual__grid">
      <pattern id="visual-grid" x="0" y="0" width="120" height="120" patternUnits="userSpaceOnUse">
        <rect fill="none" stroke={theme.color.primary.light} strokeWidth={0.15} className="checker" x="0" y="0" width="120" height="120" />
      </pattern>
      <rect x="0" y="0" width="100%" height="100%" fill="url(#visual-grid)" />
    </svg>
  )
}

export default Grid;