import theme from '@css/theme.style.json';

import './waves.styl';

function Waves() {
  return (
    <svg className="visual__waves" width="100%" height="100%" viewBox="0 0 1000 1000" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
      <defs>
        <path id="wavepath" d="M 0 2000 0 500 Q 62.5 440 125 500 t 125 0 125 0 125 0 125 0 125 0 125 0 125 0 125 0 125 0 125 0  v1000 z" />
        <path id="motionpath" d="M -250 0 0 0" /> 
        <path id="wavepath2" d="M 0 2000 0 500 Q 99.5 440 199 500 t 199 0 199 0 199 0 199 0 199 0 199 0 199 0  v1000 z" />
        <path id="motionpath2" d="M -398 0 0 0" /> 
      </defs>
      <g>
        <use xlinkHref="#wavepath" y="340" fill={theme.color.primary.dark} fillOpacity={0.6}>
          <animateMotion
            dur="8.5s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#motionpath" />
          </animateMotion>
        </use>
      </g>
      <g>
        <use xlinkHref="#wavepath2" y="370" fill={theme.color.primary.dark} fillOpacity={1}>
          <animateMotion
            dur="7s"
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#motionpath2" />
          </animateMotion>
        </use>
      </g>
    </svg>
  )
}

export default Waves;