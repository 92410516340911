const defaultState = {
  navMenuOpen: false,
};

function navMenu(state = defaultState, action) {
  switch (action.type) {
    case 'NAV_MENU_CLOSE':
      return {
        ...state, 
        navMenuOpen: false,
      }
    case 'NAV_MENU_OPEN':
      return {
        ...state, 
        navMenuOpen: true,
      }
    case 'NAV_MENU_TOGGLE':
      return {
        ...state, 
        navMenuOpen: !state.navMenuOpen,
      }
    default:
      return state;
  }
}

export default navMenu;