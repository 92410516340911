import {
  useCallback, useRef, useState, useEffect, 
} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LocalizedNavLink } from '@utils/i18n/i18n';
import { useOnClickOutside } from '@services/hooks.service';
// import routes from '@App/app.routes';
// import { isMobile } from '@services/utils.service';

// import NavMenu from './NavMenu/NavMenu';
import { closeNavMenu } from './NavMenu/nav-menu.redux.actions';

import './navbar.styl';

function Navbar() {
  // const { t } = useTranslation();
  // const mediaBreakpoint = useSelector(state => state.appWindow.resizeProps.mediaBreakpoint);
  const navMenuOpen = useSelector(state => state.navMenu.navMenuOpen);
  const navbarRef = useRef();
  const dispatch = useDispatch();
  const [isShrunk, setShrunk] = useState(false);
  
  useOnClickOutside(navbarRef, useCallback(() => {
    return navMenuOpen && dispatch(closeNavMenu());
  }, [dispatch, navMenuOpen]));

  useEffect(() => {
    const handler = () => {
      setShrunk(() => {
        return document.documentElement.scrollTop > 20;
      });
    };

    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  return (
    <div 
      ref={navbarRef}
      className={`navbar ${isShrunk ? ' navbar--sticky ' : ''}${navMenuOpen ? 'navbar--mobile-nav-menu-open' : ''}`} 
    >
      <LocalizedNavLink className="navbar__brand" to="/">
        <img className="navbar__brand__image" src="/img/logo/explore-logo.svg" alt="Explore VN logo" />
      </LocalizedNavLink>
      {/* <NavMenu 
        routes={routes} 
        isMobile={isMobile(mediaBreakpoint, props.mobileMenuMaxMediaBreakpoint)} 
      /> */}
    </div>
  );
}

export default Navbar;
