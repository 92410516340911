import theme from '@css/theme.style.json';

import './bubbles.styl';

function Bubbles() {
  return (
    <svg viewBox="0 0 1920 1080" xmlns="http://www.w3.org/2000/svg">
      <circle className="bubbles__buble bubbles__buble1" r="4" fill={theme.color.primary.light}>
        <animateMotion
          dur="30s"
          repeatCount="indefinite"
          path="M193.5,1054.5c0,0-39-63-7-109s-42-112-48-182s60-116.8,56-195.4c-4-78.6-53.8-177.5-56.4-221.5
          c-2.6-44,49.7-144.7,56.6-217.8"
        />
      </circle>
      <circle cx="500" className="bubbles__buble bubbles__buble2" r="4" fill={theme.color.primary.light}>
        <animateMotion
          dur="30s"
          begin="5s"
          repeatCount="indefinite"
          path="M193.5,1054.5c0,0-39-63-7-109s-42-112-48-182s60-116.8,56-195.4c-4-78.6-53.8-177.5-56.4-221.5
          c-2.6-44,49.7-144.7,56.6-217.8"
        />
      </circle>
      <circle cx="1600" className="bubbles__buble bubbles__buble3" r="2" fill={theme.color.primary.light}>
        <animateMotion
          dur="30s"
          begin="8s"
          repeatCount="indefinite"
          path="M193.5,1054.5c0,0-39-63-7-109s-42-112-48-182s60-116.8,56-195.4c-4-78.6-53.8-177.5-56.4-221.5
          c-2.6-44,49.7-144.7,56.6-217.8"
        />
      </circle>
      <circle cx="50" className="bubbles__buble bubbles__buble4" r="2" fill={theme.color.primary.light}>
        <animateMotion
          dur="30s"
          begin="2s"
          repeatCount="indefinite"
          path="M193.5,1054.5c0,0-39-63-7-109s-42-112-48-182s60-116.8,56-195.4c-4-78.6-53.8-177.5-56.4-221.5
          c-2.6-44,49.7-144.7,56.6-217.8"
        />
      </circle>
      <circle cx="850" className="bubbles__buble bubbles__buble5" r="3" fill={theme.color.primary.light}>
        <animateMotion
          dur="30s"
          begin="10s"
          repeatCount="indefinite"
          path="M193.5,1054.5c0,0-39-63-7-109s-42-112-48-182s60-116.8,56-195.4c-4-78.6-53.8-177.5-56.4-221.5
          c-2.6-44,49.7-144.7,56.6-217.8"
        />
      </circle>
      <circle cx="1100" className="bubbles__buble bubbles__buble6" r="4" fill={theme.color.primary.light}>
        <animateMotion
          dur="30s"
          begin="20s"
          repeatCount="indefinite"
          path="M193.5,1054.5c0,0-39-63-7-109s-42-112-48-182s60-116.8,56-195.4c-4-78.6-53.8-177.5-56.4-221.5
          c-2.6-44,49.7-144.7,56.6-217.8"
        />
      </circle>
      <circle cx="100" className="bubbles__buble bubbles__buble7" r="2" fill={theme.color.primary.light}>
        <animateMotion
          dur="30s"
          begin="18s"
          repeatCount="indefinite"
          path="M193.5,1054.5c0,0-39-63-7-109s-42-112-48-182s60-116.8,56-195.4c-4-78.6-53.8-177.5-56.4-221.5
          c-2.6-44,49.7-144.7,56.6-217.8"
        />
      </circle>
      <circle cx="1500" className="bubbles__buble bubbles__buble8" r="4" fill={theme.color.primary.light}>
        <animateMotion
          dur="30s"
          begin="14s"
          repeatCount="indefinite"
          path="M193.5,1054.5c0,0-39-63-7-109s-42-112-48-182s60-116.8,56-195.4c-4-78.6-53.8-177.5-56.4-221.5
          c-2.6-44,49.7-144.7,56.6-217.8"
        />
      </circle>
    </svg>
  );
}

export default Bubbles;