const closeNavMenu = () => { 
  return {
    type: 'NAV_MENU_CLOSE',
  }
}

const openNavMenu = () => { 
  return {
    type: 'NAV_MENU_OPEN',
  }
}

const toggleNavMenu = () => { 
  return {
    type: 'NAV_MENU_TOGGLE',
  }
}

export {
  closeNavMenu,
  openNavMenu,
  toggleNavMenu,
}