import Footer from '@App/components/Footer/Footer';
import Visual from './Visual/Visual';
import About from './About/About';
import Features from './Features/Features';
import Media from './Media/Media';
import Calendar from './Calendar/Calendar';

import './landing.styl';

function Landing() {
  return (
    <div className="landing">
      <Visual />
      <About />
      <Media />
      <Features />
      <Calendar />
      <Footer />
    </div>
  )
}

export default Landing;