import { useEffect } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import { UserbackProvider } from '@userback/react';
import { ThemeProvider } from '@nazka/nazka.mapframe.components';

import TranslationsProvider, { LocalizedRoutes } from '@utils/i18n/i18n';
import newsletterInit from '@App/components/Footer/footer.utils';
import AppWindow from '@utils/AppWindow/AppWindow';
import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
import { MEDIA_BREAKPOINT_ENTRIES } from '@utils/AppWindow/app-window.constants';
import CookieBar from '@utils/CookieBar/CookieBar';

import Navbar from '@App/components/Navbar/Navbar';
import NoMatch from '@App/pages/NoMatch/NoMatch';
import routes from '@App/app.routes';
import store from '@App/app.store';
import translator from '@App/app.translator';

import theme from '@css/theme.style.json';

import './app.styl';

function App() {
  useEffect(() => {
    newsletterInit();
  })

  useEffect(() => {
    document.documentElement.setAttribute('data-app-initialised', true);
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <UserbackProvider token={import.meta.env.VITE_USERBACK_TOKEN} options={{ priority: 'high' }}>
          <BrowserRouter>
            <ErrorBoundary>
              <TranslationsProvider translator={translator}>
                <Navbar 
                  mobileSearchMaxMediaBreakpoint={MEDIA_BREAKPOINT_ENTRIES[2][1]} 
                  mobileMenuMaxMediaBreakpoint={MEDIA_BREAKPOINT_ENTRIES[2][1]} 
                />
                <LocalizedRoutes>
                  {routes.map(route => (
                    <Route 
                      key={route.path}
                      path={route.path} 
                      element={route.element}
                    />
                  ))}
                  <Route path="*" element={<NoMatch />} />
                </LocalizedRoutes>
                <AppWindow />
                <CookieBar />
              </TranslationsProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </UserbackProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
