import theme from '@css/theme.style.json';
import './meteors.styl';

function Meteors(props) {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className="visual__meteors"
      {...props}
    >
      <g className="visual__meteors">
        <g className="visual__meteors__meteor" x="233.3">
          <line stroke={theme.color.primary.light} strokeWidth={4} strokeOpacity={0.2} x1="1043.3" y1="204.8" x2="1743.6" y2="-495.5" />
        </g>
      </g>
    </svg>
  )
}

export default Meteors;
