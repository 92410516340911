import Satellite from '../Visual/Satellite/Satellite';

import './about.styl';

function About() {
  return (
    <section className="about" id="about">
      <div className="about__block about__block--1">
        <img className="about_block__img about_block__img--lakes" src="/img/about/water-bodies.png" />
        <p className="about_block__text">
          Satellite imagery allows for very cost-efficient environmental monitoring. 
          EXPLORE takes the raw satellite data and transforms it into actionable end 
          products and information. One example of this is the monitoring of water 
          quality. We work together with the remote sensing team of 
          {' '}
          <a 
            className="visual__intro__text__url" 
            href="https://blog.vito.be/remotesensing/explore-vn-water-quality-information-for-vietnams-inland-coastal-waters" 
            target="_blank" 
            rel="noreferrer"
          >
            VITO
          </a>
          {' '}
          and use their state-of-the-art algorithms for some essential 
          indicators like chlorophyll-a content or water turbidity.
        </p>
      </div>
      <div className="about__block">
        <p className="about_block__text">
          The Copernicus Sentinel-2 satellites play a crucial role in EXPLORE as they 
          conduct optical scans of the entire globe every 5 days with a high resolution 
          of 10 meters. EXPLORE automatically downloads and processes these images. This 
          frequent and detailed coverage enables users of EXPLORE to monitor their 
          environment in near-real time. Products are made available in an easy-to-use 
          web application.
        </p>
        <Satellite className="about_block__img about_block__img--satellite" animateActive={false} />
      </div>
    </section>
  )
}

export default About;
