/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import { validateEmail } from '@services/utils.service';

function newsletterInit() {
  const newsletterFormEl = document.querySelector('.js-newsletter-form');
  const errorDisplay = newsletterFormEl && document.getElementById('newsletter-error');
  const successDisplay = newsletterFormEl && document.getElementById('newsletter-success');
  const newsletterFormInputEl = newsletterFormEl && newsletterFormEl.querySelector('input[type="email"]');
  const newsletterFormCheckboxEl = newsletterFormEl && newsletterFormEl.querySelector('input[type="checkbox"]');
  const newsletterFormSubmitEl = newsletterFormEl && newsletterFormEl.querySelector('button[type="submit"]');
  const newsletterFromLanguageEl = newsletterFormEl && newsletterFormEl.querySelector('input[type="hidden"]')

  if (!newsletterFormEl || !newsletterFormInputEl || !newsletterFormCheckboxEl || !newsletterFormSubmitEl) {
    return;
  }

  newsletterFormInputEl.addEventListener('keyup', (e) => {
    const { value } = e.target;

    newsletterFormSubmitEl.disabled = !newsletterFormCheckboxEl.checked || !value;
  });

  newsletterFormCheckboxEl.addEventListener('change', (e) => {
    const { checked } = e.target;
    newsletterFormSubmitEl.disabled = !checked || !newsletterFormInputEl.value;
  });

  newsletterFormEl.addEventListener('submit', (e) => {
    errorDisplay.style.display = 'none';
    e.preventDefault();

    if (!newsletterFormInputEl.value) {
      return;
    }

    if (!validateEmail(newsletterFormInputEl.value)) {
      errorDisplay.style.display = 'block';
      errorDisplay.innerHTML = 'Please provide valid email!'
      return;
    }
    newsletterFormEl.classList.add('loading');
    subscribeToNewsletter(newsletterFormInputEl.value, newsletterFromLanguageEl.value).then((response) => {
      if (response && response.success) {
        newsletterFormEl.classList.add('subscribed');
        successDisplay.innerHTML = response.message
      } else {
        errorDisplay.style.display = 'block';
        errorDisplay.innerHTML = response.message
      }
    })
      .finally(() => newsletterFormEl.classList.remove('loading'))
  });
}

/*  Helper Functions
    ================================================================ */

function subscribeToNewsletter(email, language) {
  const urlPrefix = `${window.location.protocol}//${window.location.host}`;
  return new Promise((resolve) => {
    postAjax(`${urlPrefix}/mailchimp`, { email, language }, (response) => {
      console.log(response);
      return resolve(JSON.parse(response));
    });
  })
}

function postAjax(url, data, success) {
  // eslint-disable-next-line no-undef
  const xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
  const params = typeof data === 'string' ? data : Object.keys(data).map((k) => {
    const key = encodeURIComponent(k);
    const value = encodeURIComponent(data[k]);

    return `${key}=${value}`;
  }).join('&');

  xhr.open('POST', url);
  xhr.onreadystatechange = () => {
    if (xhr.readyState > 3 && xhr.status === 200) {
      success(xhr.responseText);
    }
  };
  xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
  xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  xhr.send(params);

  return xhr;
}

export default newsletterInit;